var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-provider", {
    attrs: { mode: _vm.vmode, vid: _vm.vid, name: _vm.name, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ dirty, validated, valid, errors }) {
          return [
            _c(
              "b-form-group",
              {
                class: {
                  asterisk:
                    Object.keys(_vm.rules).includes("required") ||
                    (Object.keys(_vm.rules).includes("required_if") &&
                      !valid) ||
                    (Object.keys(_vm.rules).includes("required_with") &&
                      !valid),
                },
                attrs: {
                  id: `input-group-${_vm.uid}`,
                  label: `${_vm.label}`,
                  "label-for": `input-${_vm.uid}`,
                },
                scopedSlots: _vm._u(
                  [
                    _vm.multiple && !_vm.taggable
                      ? {
                          key: "label",
                          fn: function () {
                            return [
                              _vm.hasSel
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      attrs: { id: `tooltip-${_vm.id}` },
                                      on: {
                                        click: function ($event) {
                                          return _vm.tooltip()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "info-circle" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(
                                "\n      " + _vm._s(_vm.label) + "\n      "
                              ),
                              _vm.hasSel
                                ? _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        show: _vm.showTooltip,
                                        target: `tooltip-${_vm.id}`,
                                        placement: "top",
                                      },
                                      on: {
                                        "update:show": function ($event) {
                                          _vm.showTooltip = $event
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(_vm.tltp) +
                                          "\n      "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              },
              [
                _vm._v(" "),
                _c(
                  "multiselect",
                  {
                    class: _vm.getValidationStateClass({
                      dirty,
                      validated,
                      valid,
                      errors,
                    }),
                    attrs: {
                      id: _vm.id,
                      name: `input-${_vm.uid}`,
                      options: _vm.options.map((option) => option.value),
                      "custom-label": _vm.customLabel,
                      taggable: _vm.taggable,
                      multiple: _vm.multiple,
                      disabled: _vm.disabled,
                      closeOnSelect: _vm.closeOnSelect,
                      searchable: true,
                      loading: _vm.isLoading,
                      placeholder: _vm.placeholder,
                      selectLabel: "[Invio]",
                      "tag-placeholder": _vm.getDictionary(
                        "multiselect_no_results",
                        "form"
                      ),
                      selectedLabel: _vm.getDictionary(
                        "multiselect_selected",
                        "form"
                      ),
                      deselectLabel: "[Invio]",
                      "aria-describedby": `input-${_vm.id}-live-feedback`,
                      "preselect-first": _vm.preselectFirst,
                    },
                    on: {
                      tag: _vm.doNotAddTag,
                      select: _vm.onSelect,
                      remove: _vm.onRemove,
                      input: _vm.onInput,
                      "search-change": _vm.asyncFind,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function ({ values, isOpen }) {
                            return _vm.multiple && !_vm.taggable
                              ? [
                                  values.length && !isOpen
                                    ? _c(
                                        "span",
                                        { staticClass: "multiselect__single" },
                                        [
                                          _vm._v(
                                            _vm._s(values.length) +
                                              " opzioni selezionate"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.inputVal,
                      callback: function ($$v) {
                        _vm.inputVal = $$v
                      },
                      expression: "inputVal",
                    },
                  },
                  [
                    _c("template", { slot: "noOptions" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getDictionary("multiselect_no_options", "form")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "noResult" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getDictionary("multiselect_no_results", "form")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._v(" "),
                    _vm.multiple && !_vm.taggable
                      ? _c("template", { slot: "tag" }, [_vm._v(_vm._s(""))])
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "b-form-invalid-feedback",
                  { attrs: { id: `input-${_vm.id}-live-feedback` } },
                  [_vm._v(_vm._s(errors[0]))]
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }